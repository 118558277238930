import React from "react";
import token from "../images/token.png";
import sway from "../images/sway.jpg";

export default () => {
  return (
    <div className="home-page">
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="column primary">
              <h2 className="page-title">PROJECTS</h2>
              <p className="page-description">
                Lately, I've been thinking that side projects are a great way to
                try new things outside of work. I know, it's shocking. I enjoy
                projects with an entrepreneurial bent. I can't motivate myself
                to work on something people may never use. Someone has to use
                what I work on. An ideal side project will allow me to make an
                impact on industries that are underserved by tech. My second
                wish would be to use my newfound Haskell knowledge, but that's
                not as important. Hit me up if you want to collab and drop some
                hot apps.
              </p>
            </div>
            <div className="column secondary">
              <div className="page-thumbnail">
                <div className="image scrollin-up scrollin-scaleimg scrollin-active">
                  <div className="parallax-image">
                    <div className="parallax-image-inner">
                      <img
                        src="https://images.unsplash.com/photo-1544159465-672a2b0d5f2c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
                        alt="red painting"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="article-grid-section">
        <div className="container">
          <ol className="article-grid article-grid-featured">
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <a
                    className="entry-thumbnail"
                    href="https://tokn.herokuapp.com"
                  >
                    <img src={token} alt="Token logo" />
                  </a>
                </header>
                <div className="entry-body">
                  <h2 className="entry-title">TOKEN</h2>
                  <div className="entry-content">
                    <p>
                      A web application that helps teachers manage their
                      students and their classrooms. The goal is to help
                      teachers effectively reward students to promote positive
                      habits. We are piloting in Pittsburgh schools. Reach out
                      if you're interested!
                      <br />
                      <br />
                      Frontend built with React + Redux. Backend built with
                      Django REST Framework.
                    </p>
                  </div>
                </div>
              </article>
            </li>
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img src={sway} alt="Sway logo" />
                  </div>
                </header>
                <div className="entry-body">
                  <h2 className="entry-title">SWAY</h2>
                  <div className="entry-content">
                    <p>
                      We built Sway to facilitate coordinating Princeton
                      Reunions. Every year thousands of alumni return for
                      Reunions. Situation gets hectic for students managing the event,
                      and Sway made the task easier.
                      <br />
                      <br />
                      My first foray into React Native. Worked out okay.
                    </p>
                  </div>
                </div>
              </article>
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
};
