import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <header className="site-header">
      <div className="container">
        <Link to="/">
          <h1 className="site-header-logo">KUSH PATEL</h1>
        </Link>
      </div>
    </header>
  );
};
