import React from "react";

export default () => {
  return (
    <div className="home-page">
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="column primary">
              <h2 className="page-title">BOOKS</h2>
              <p className="page-description">
                I have not written a book, yet. "Things I am reading" sounds
                awkward, so I settled for calling this section "Books." A little
                misleading until I write my book. I'll try to keep track of
                books and articles I read every year. I'll refrain from writing
                reviews. There are too many reviews on the internet as it is.
              </p>
            </div>
            <div className="column secondary">
              <div className="page-thumbnail">
                <div className="image scrollin-up scrollin-scaleimg scrollin-active">
                  <div className="parallax-image">
                    <div className="parallax-image-inner">
                      <img
                        src="https://images.unsplash.com/photo-1547555999-14e818e09e33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80"
                        alt="three books photos"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="article-grid-section">
        <div className="container">
          <ol className="article-grid article-grid-boxed">
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img
                      src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1544744853l/4406.jpg"
                      alt="East of Eden book cover"
                    />
                  </div>
                  <div className="entry-meta">
                    <h2 className="entry-title">East of Eden</h2>
                  </div>
                </header>
              </article>
            </li>
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img
                      src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1535115320l/40121378._SY475_.jpg"
                      alt="Atomic Habits book cover"
                    />
                  </div>
                  <div className="entry-meta">
                    <h2 className="entry-title">Atomic Habits</h2>
                  </div>
                </header>
              </article>
            </li>
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img
                      src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1449840070l/25587599._SX318_.jpg"
                      alt="Haskell Programming From First Principles book cover"
                    />
                  </div>
                  <div className="entry-meta">
                    <h2
                      className="entry-title"
                      style={{ "word-break": "normal" }}
                    >
                      Haskell Programming From First Principles
                    </h2>
                  </div>
                </header>
              </article>
            </li>
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img
                      src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1430514347l/25241317.jpg"
                      alt="Black Flags: Rise of ISIS book cover"
                    />
                  </div>
                  <div className="entry-meta">
                    <h2 className="entry-title">
                      Black Flags: The Rise of ISIS
                    </h2>
                  </div>
                </header>
              </article>
            </li>
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img
                      src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1404613595l/13.jpg"
                      alt="Hitchhiker's Guide to the Galaxy book cover"
                    />
                  </div>
                  <div className="entry-meta">
                    <h2
                      className="entry-title"
                      style={{ "word-break": "normal" }}
                    >
                      Hitchhiker's Guide to the Galaxy
                    </h2>
                  </div>
                </header>
              </article>
            </li>
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img
                      src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1555447414l/44767458.jpg"
                      alt="Dune book cover"
                    />
                  </div>
                  <div className="entry-meta">
                    <h2 className="entry-title">DUNE</h2>
                  </div>
                </header>
              </article>
            </li>
            <li className="article-grid-item">
              <article className="entry">
                <header className="entry-header">
                  <div className="entry-thumbnail">
                    <img
                      src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327868566l/2429135.jpg"
                      alt="The Girl with the Dragon Tattoo book cover"
                    />
                  </div>
                  <div className="entry-meta">
                    <h2 className="entry-title">
                      The Girl with the Dragon Tattoo
                    </h2>
                  </div>
                </header>
              </article>
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
};
