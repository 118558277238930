import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div className="home-page">
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="column primary">
              <div className="home-hero-nav">
                <ul>
                  <li>
                    <Link to="/about">
                      <span>ABOUT</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects">
                      <span>PROJECTS</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/books">
                      <span>BOOKS</span>
                    </Link>
                  </li>
                  <li>
                    <a href="https://github.com/kushpa42/">
                      <span>GITHUB</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/kush-patel-5a595a148/">
                      <span>LINKEDIN</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column secondary">
              <div className="page-thumbnail">
                <div className="image scrollin-up scrollin-scaleimg scrollin-active">
                  <div className="parallax-image">
                    <div className="parallax-image-inner">
                      <img
                        src="https://images.unsplash.com/photo-1562128940-04734aad3c95?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
