import React from "react";

export default () => {
  return (
    <div className="home-page">
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="column primary">
              <h2 className="page-title">ABOUT</h2>
              <p className="page-description">
                Hi, my name is Kush, and I struggle with writing about myself.
                I'm sure other people do too. That's normal. In fact, I'm more
                concerned about the people who display their lives for all of
                the internet. There are no clear guidelines on what the "About"
                section should contain. The lack of structure is unsettling. I
                think it should contain information about myself that indicates
                how I see the world. I think that's good enough. Right, that
                settles it.
                <br />
                <br />
                I was born in India and raised in America. I graduated from
                Princeton University in 2018. Now I work as a software engineer.
                I like the idea of building something where there was nothing. I
                enjoy the craftsmanship in good software. Software engineering
                is like digital woodworking. The sense of ownership that comes
                with software is great too. Maybe it's the millenial in me -- I
                know I will never own a home, so I might as well own some code.
                <br />
                <br />
                Outside of work, I like learning new things. And I want those
                things to be outside of my comfort zone. There is no greater
                satisfaction for me than doing something I felt afraid of doing.
                So far I've learned salsa dancing, and pottery. I prefer
                dancing. I'm also trying to run a marathon eventually. I always
                get injured. I consider my flat feet a weakness.
              </p>
            </div>
            <div className="column secondary">
              <div className="page-thumbnail">
                <div className="image scrollin-up scrollin-scaleimg scrollin-active">
                  <div className="parallax-image">
                    <div className="parallax-image-inner">
                      <img
                        src="https://images.unsplash.com/photo-1547241850-eb8a8754f95c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
