import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./Header";
import HomePage from "./HomePage";
import About from "./About";
import Projects from "./Projects";
import Books from "./Books";

const App = () => {
  return (
    <div className="document-wrapper">
      <BrowserRouter>
        <Header />
        <div className="content-wrapper">
          <main className="site-main">
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/about" exact component={About} />
              <Route path="/projects" exact component={Projects} />
              <Route path="/books" exact component={Books} />
            </Switch>
          </main>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
